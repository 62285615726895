<template>
  <div class="section-title" :style="sectionStyles">
    <span
      v-if="isMedicalBackgroundSection"
      class="section-title__icon section-title-medical-background-icon"
    >
      <img
        src="@/modules/questionnaire/assets/images/icons/medical-background.svg"
        alt="medical-background icon"
      />
    </span>
    <span v-else class="section-title__icon section-title-medical-background-icon" />

    <span class="section-title__title" :class="titleClasses">{{ $t(title) }}</span>
  </div>
</template>

<script>
import { camelCase } from 'lodash';

import { DYSFUNCTION_NAMES } from '@/modules/questionnaire/constants/dysfunctions';

const SECTION_TITLE = {
  [DYSFUNCTION_NAMES.OXIDATIVE_STRESS]: 'heading.oxidativeStress',
  [DYSFUNCTION_NAMES.SKIN_APPEARANCE]: 'heading.skinAppearance',
  [DYSFUNCTION_NAMES.SKIN_REDNESS]: 'heading.skinRedness',
  [DYSFUNCTION_NAMES.SKIN_DRYNESS]: 'heading.skinDryness',
  [DYSFUNCTION_NAMES.SKIN_OILINESS]: 'heading.skinOiliness',
  [DYSFUNCTION_NAMES.SKIN_TEXTURE]: 'heading.skinTexture',
  [DYSFUNCTION_NAMES.PIMPLES]: 'heading.pimples',
  [DYSFUNCTION_NAMES.SKIN_PIGMENTATION]: 'heading.skinPigmentation',
  medicalBackground: 'questionnaire.sectionTitle.medicalBackground'
};

export default {
  name: 'SectionTitle',
  props: {
    sectionName: {
      type: String,
      required: true
    }
  },
  computed: {
    title() {
      return SECTION_TITLE[camelCase(this.sectionName)];
    },
    sectionStyles() {
      return {
        '--icon-color': `var(--u-color-${this.sectionName}-500, transparent)`
      };
    },
    isMedicalBackgroundSection() {
      return this.sectionName === 'medical-background';
    },
    titleClasses() {
      return {
        'section-title__title--medical-background': this.isMedicalBackgroundSection
      };
    }
  }
};
</script>

<style lang="scss" scoped>
.section-title {
  display: flex;
  align-items: center;
  font-family: var(--u-font-family-Helvetica);

  &__icon {
    background-color: var(--icon-color);
    width: 14px;
    height: 14px;
    border-radius: 4px;
    margin-right: 8px;
  }

  &__title {
    text-transform: uppercase;
    color: var(--u-color-grey-900);
    font-size: 14px;
    line-height: 18px;
    letter-spacing: 0.4px;
    font-weight: 600;

    &--medical-background {
      color: var(--u-color-grey-600);
    }
  }
}

.section-title-medical-background-icon {
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
