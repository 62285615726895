<template>
  <article class="what-does-it-mean">
    <header class="what-does-it-mean__header u-typography-helvetica u-text u-text--l">
      {{ $t('questionnaire.whatDoesItMean') }}
    </header>
    <header
      class="what-does-it-mean__header what-does-it-mean__header--desktop u-typography-helvetica u-text u-text--m"
    >
      {{ $t('questionnaire.whatDoesItMean') }}
    </header>

    <section
      class="what-does-it-mean__message u-typography-helvetica u-text u-text--s"
      :style="messageStyles"
    >
      {{ $t(message) }}
    </section>

    <section
      class="what-does-it-mean__message what-does-it-mean__message--desktop u-typography-helvetica u-text u-text--link u-text--s"
      :style="messageStyles"
    >
      {{ $t(message) }}
    </section>

    <img width="56" class="what-does-it-mean__icon" :src="dysfunctionIcon" :alt="dysfunction" />
    <section class="what-does-it-mean__description description">
      <header class="description__header u-typography-helvetica u-text u-text--m">
        {{ dysfunctionTitle }}
      </header>

      <main
        class="description__text description__text--desktop u-typography-helvetica u-text u-text--s"
      >
        {{ $t(description) }}
      </main>

      <main
        class="description__text description__text--mobile u-typography-helvetica u-text u-text--xs"
      >
        {{ $t(description) }}
      </main>
    </section>

    <section class="what-does-it-mean__tell-us-more description">
      <header class="description__header u-typography-helvetica u-text u-text--m">
        {{ $t('questionnaire.tellUsMore.title') }}
      </header>

      <main
        class="description__text description__text--desktop u-typography-helvetica u-text u-text--s"
      >
        {{ $t('questionnaire.tellUsMore.content') }}
      </main>

      <main
        class="description__text description__text--mobile u-typography-helvetica u-text u-text--xs"
      >
        {{ $t('questionnaire.tellUsMore.content') }}
      </main>
    </section>
  </article>
</template>

<script>
import { camelCase } from 'lodash';

export default {
  name: 'WhatDoesItMean',
  props: {
    dysfunction: {
      type: String,
      required: true
    },
    message: {
      type: String,
      required: true
    },
    title: {
      type: String,
      required: true
    },
    description: {
      type: String,
      required: true
    }
  },
  computed: {
    dysfunctionIcon() {
      return require(`universkin-shared/src/assets/images/svg/dysfunction-icons/${this.dysfunction}.svg`);
    },
    messageStyles() {
      return {
        color: `var(--u-color-${this.dysfunction}-contrast)`
      };
    },
    dysfunctionTitle() {
      return this.$t(`heading.${camelCase(this.dysfunction)}`);
    }
  }
};
</script>

<style lang="scss" scoped>
@import '~universkin-shared/src/assets/styles/scss/sizes.scss';

.what-does-it-mean {
  color: var(--u-color-graphite);
  padding: 16px;

  &__icon {
    width: 43px;
    margin-bottom: 16px;
  }

  &__header {
    margin-bottom: 16px;

    &--desktop {
      display: none;
    }
  }

  &__message,
  &__description {
    padding-bottom: 16px;
    margin-bottom: 16px;
    border-bottom: 1px solid var(--u-color-grey-200);

    &--desktop {
      display: none;
    }
  }
}

.description {
  &__header {
    margin-bottom: 16px;
  }

  &__text {
    color: var(--u-color-grey-700);

    &--desktop {
      display: none;
    }
  }
}

@media (min-width: $desktop-start) {
  .what-does-it-mean {
    position: relative;
    padding: 24px;

    &__header {
      display: none;
      margin-bottom: 24px;

      &--desktop {
        display: block;
      }
    }

    &__message {
      display: none;

      &--desktop {
        display: block;
      }
    }

    &__icon {
      position: absolute;
      left: 24px;
      top: 72px;
      width: 70px;
    }

    &__message,
    &__description,
    &__tell-us-more {
      margin-left: 149px;
    }
  }

  .description {
    &__header {
      margin-bottom: 8px;
    }

    &__text {
      &--desktop {
        display: initial;
      }

      &--mobile {
        display: none;
      }
    }
  }
}
</style>
