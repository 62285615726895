const ALL_DYSFUNCTION_NAME = 'all';

const DYSFUNCTION_NAMES = {
  OXIDATIVE_STRESS: 'oxidativeStress',
  SKIN_APPEARANCE: 'skinAppearance',
  SKIN_REDNESS: 'skinRedness',
  SKIN_DRYNESS: 'skinDryness',
  SKIN_OILINESS: 'skinOiliness',
  SKIN_TEXTURE: 'skinTexture',
  PIMPLES: 'pimples',
  SKIN_PIGMENTATION: 'skinPigmentation'
};

const DYSFUNCTION_SEVERETY = {
  NO: 'no',
  LOW: 'low',
  MEDIUM: 'medium',
  HIGH: 'high',
  VERY_HIGH: 'veryHigh'
};

export { DYSFUNCTION_NAMES, ALL_DYSFUNCTION_NAME, DYSFUNCTION_SEVERETY };
