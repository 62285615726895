<template>
  <article class="why-we-ask-this">
    <header class="why-we-ask-this__title u-typography-helvetica u-text u-text--l">
      {{ $t('questionnaire.whyWeAskThis') }}
    </header>
    <img width="44" class="why-we-ask-this__icon" :src="dysfunctionIcon" :alt="dysfunction" />
    <div class="why-we-ask-this__dysfunction-title u-typography-helvetica u-text u-text--m">
      {{ dysfunctionTitle }}
    </div>

    <div
      class="why-we-ask-this__description why-we-ask-this__description--mobile u-typography-helvetica u-text u-text--xs"
    >
      {{ description }}
    </div>

    <div
      class="why-we-ask-this__description why-we-ask-this__description--desktop u-typography-helvetica u-text u-text--s"
    >
      {{ description }}
    </div>
  </article>
</template>

<script>
import { camelCase } from 'lodash';

export default {
  name: 'WhyWeAskThis',
  props: {
    dysfunction: {
      type: String,
      required: true
    }
  },
  computed: {
    dysfunctionIcon() {
      return require(`universkin-shared/src/assets/images/svg/dysfunction-icons/${this.dysfunction}.svg`);
    },
    dysfunctionTitle() {
      return this.$t(`heading.${camelCase(this.dysfunction)}`);
    },
    description() {
      return this.$t(`subHeading.${camelCase(this.dysfunction)}`);
    }
  }
};
</script>

<style lang="scss" scoped>
@import '~universkin-shared/src/assets/styles/scss/sizes.scss';

.why-we-ask-this {
  color: var(--u-color-graphite);
  padding: 16px;

  &__title {
    margin-bottom: 24px;
  }

  &__icon {
    width: 44px;
    margin-bottom: 24px;
    display: block;
  }

  &__dysfunction-title {
    margin-bottom: 16px;
  }

  &__description {
    &--desktop {
      display: none;
    }
  }
}

@media (min-width: $desktop-start) {
  .why-we-ask-this {
    position: relative;
    padding: 24px;

    &__icon {
      position: absolute;
      left: 30px;
      top: 86px;
      width: 77px;
    }

    &__dysfunction-title {
      width: 560px;
      margin-left: auto;
      margin-bottom: 8px;
    }

    &__description {
      width: 560px;
      margin-left: auto;

      &--desktop {
        display: block;
      }

      &--mobile {
        display: none;
      }
    }
  }
}
</style>
