<template>
  <modal
    name="why-we-ask-this-modal"
    :classes="['why-we-ask-this-modal']"
    transition="scale"
    adaptive
    scrollable
    :width="795"
    height="auto"
    @before-open="beforeOpen"
  >
    <div class="why-we-ask-this-modal-content">
      <why-we-ask-this v-if="dysfunction" :dysfunction="dysfunction" />

      <u-icon class="why-we-ask-this-modal-content__close-button" name="close" @click="onClose" />
    </div>
  </modal>
</template>

<script>
import { UIcon } from 'universkin-shared';

import WhyWeAskThis from '@/modules/questionnaire/new-design-components/why-we-ask-this/WhyWeAskThis';

export default {
  name: 'WhyWeAskThisModal',
  components: { WhyWeAskThis, UIcon },
  data() {
    return {
      dysfunction: null
    };
  },
  methods: {
    beforeOpen({ params: { dysfunction } }) {
      this.dysfunction = dysfunction;
    },
    onClose() {
      this.$modal.hide('why-we-ask-this-modal');
    }
  }
};
</script>

<style>
.why-we-ask-this-modal.v--modal-box {
  padding-bottom: 0;
}
</style>

<style lang="scss" scoped>
@import '~universkin-shared/src/assets/styles/scss/sizes.scss';

.why-we-ask-this-modal-content {
  background-color: var(--u-color-white);
  border-radius: 10px;
  position: relative;
  margin: 16px;

  &__close-button {
    cursor: pointer;
    position: absolute;
    top: 16px;
    right: 16px;
  }
}

@media (min-width: $desktop-start) {
  .why-we-ask-this-modal-content {
    &__close-button {
      top: 24px;
      right: 24px;
    }
  }
}
</style>
