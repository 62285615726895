<template>
  <app-modal ref="modal">
    <div class="what-does-it-mean-modal-content">
      <what-does-it-mean
        v-if="dysfunction"
        :dysfunction="dysfunction"
        :message="message"
        :title="title"
        :description="description"
      />

      <u-icon v-modal-cancel class="what-does-it-mean-modal-content__close-button" name="close" />
    </div>
  </app-modal>
</template>

<script>
import { UIcon } from 'universkin-shared';

import WhatDoesItMean from '@/modules/questionnaire/new-design-components/skin-scoring/WhatDoesItMean';
import AppModal from '@/components/common/AppModal';

export default {
  name: 'WhatDoesItMeanModal',
  components: { UIcon, WhatDoesItMean, AppModal },
  props: {
    dysfunction: {
      type: String,
      default: null
    },
    message: {
      type: String,
      default: ''
    },
    title: {
      type: String,
      default: ''
    },
    description: {
      type: String,
      default: ''
    }
  },
  methods: {
    open() {
      return this.$refs.modal.open();
    }
  }
};
</script>

<style lang="scss" scoped>
@import '~universkin-shared/src/assets/styles/scss/sizes.scss';

.what-does-it-mean-modal-content {
  background-color: var(--u-color-white);
  border-radius: 10px;
  position: relative;
  width: 795px;
  max-width: 100%;
  max-height: calc(var(--screen-height) - 80px);
  overflow-y: auto;

  &__close-button {
    cursor: pointer;
    position: absolute;
    top: 16px;
    right: 16px;
  }
}

@media (min-width: $desktop-start) {
  .what-does-it-mean-modal-content {
    &__close-button {
      top: 24px;
      right: 24px;
    }
  }
}
</style>
