import { render, staticRenderFns } from "./QuestionnairePageTemplate.vue?vue&type=template&id=08b5bf7f&scoped=true&"
import script from "./QuestionnairePageTemplate.vue?vue&type=script&lang=js&"
export * from "./QuestionnairePageTemplate.vue?vue&type=script&lang=js&"
import style0 from "./QuestionnairePageTemplate.vue?vue&type=style&index=0&id=08b5bf7f&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "08b5bf7f",
  null
  
)

export default component.exports