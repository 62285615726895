import { get } from 'lodash';

import { COUNTRIES, COUNTRY_STATES } from '@/api/places/COUNTRIES';
import { LOCALES, LANGUAGES } from '@/api/places/LOCALES';
import { DEFAULT_LOCALE } from '@/constants';
import { QUESTIONNAIRE_AVAILABLE_LOCALES } from '@/modules/questionnaire/api/constants';

const listSortFunction = (a, b) => a[1].localeCompare(b[1]);
const mapListSortFunction = (a, b) => a.label.localeCompare(b.label);

const getSortedCities = (country, isMap = false) => {
  const citiesList = Object.values(COUNTRIES[country.toLowerCase()].cities).reduce(
    (cities, city) => {
      const cityEntity = isMap
        ? { label: `city.${country.toLowerCase()}.${city}`, value: city.toLowerCase() }
        : [city.toLowerCase(), `city.${country.toLowerCase()}.${city}`];

      cities.push(cityEntity);

      return cities;
    },
    []
  );

  const sortFunction = isMap ? mapListSortFunction : listSortFunction;

  return citiesList.sort(sortFunction);
};

const getSupportedLocales = () => LOCALES;

const getQuestionnaireLocaleOptions = () =>
  getSupportedLocales()
    .filter(([code]) => QUESTIONNAIRE_AVAILABLE_LOCALES.includes(code.toLowerCase()))
    .map(([code, title]) => ({ code, title }));

const getSupportedLanguages = () => LANGUAGES;

const getLanguageNameForLocale = locale => {
  const result = LOCALES.find(e => e[0] === locale);

  return result ? result[1] : undefined;
};

const getShortLanguageName = language => {
  const result = LANGUAGES.find(([name]) => name.toLowerCase() === language.toLowerCase());

  return get(result, '0', DEFAULT_LOCALE);
};

const getLocaleForLanguageName = languageName => {
  const result = LOCALES.find(([, name]) => name.toLowerCase() === languageName.toLowerCase());

  return result ? result[0] : DEFAULT_LOCALE;
};

const getCountryState = (country, state) => {
  const countryStates = get(COUNTRY_STATES, country.toUpperCase(), []);

  return countryStates.find(({ value }) => state === value);
};

export {
  getSortedCities,
  getSupportedLocales,
  getQuestionnaireLocaleOptions,
  getSupportedLanguages,
  getLanguageNameForLocale,
  getShortLanguageName,
  getLocaleForLanguageName,
  getCountryState
};
