<template>
  <div class="q-progress-bar" :class="barClasses">
    <div v-if="outlined" class="q-progress-bar__outline" />
    <div class="q-progress-bar__fill" :style="fillStyles" />
  </div>
</template>

<script>
export default {
  name: 'ProgressBar',
  props: {
    /** Progress in percentages (0 - 100). */
    value: {
      type: Number,
      required: true
    },
    color: {
      type: String,
      required: true
    },
    tiny: {
      type: Boolean,
      default: false
    },
    semiTransparentBackground: {
      type: Boolean,
      default: false
    },
    outlined: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    fillStyles() {
      return {
        '--progress': `${this.value}%`,
        '--color': `var(--u-color-${this.color}-500)`,
        backgroundColor: `var(--u-color-${this.color}-500)`
      };
    },
    barClasses() {
      return {
        'q-progress-bar--tiny': this.tiny,
        'q-progress-bar--semi-transparent-background': this.semiTransparentBackground,
        'q-progress-bar--outlined': this.outlined
      };
    }
  }
};
</script>

<style lang="scss" scoped>
.q-progress-bar {
  --empty-bar-color: 239, 241, 246;
  --barSize: 8px;
  background-color: rgba(var(--empty-bar-color), 1);
  border-radius: 4px;
  height: var(--barSize);
  width: 100%;
  position: relative;
  --progress: 0%;

  &--tiny {
    --barSize: 4px;
    height: var(--barSize);
  }

  &--semi-transparent-background {
    background-color: rgba(var(--empty-bar-color), 0.4);
  }

  &--outlined {
    background-color: transparent;
  }

  &__outline {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 1px solid var(--u-color-grey-200);
    box-sizing: border-box;
    border-radius: 4px;
  }

  &__fill {
    position: absolute;
    top: 0;
    left: 0;
    width: var(--progress);
    background-color: transparent;
    border: calc(var(--barSize) / 2) solid var(--color);
    border-radius: inherit;
  }
}
</style>
