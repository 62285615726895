<template>
  <u-simple-dropdown
    class="questionnaire-language-select-dropdown"
    :value="currentValue"
    :options="dropdownOptions"
    :disabled="disabled"
    right-aligned
    @input="onInput"
  >
    <template #value>{{ currentValue.value.toUpperCase() }}</template>

    <template #option="{ option }">{{ option.label }}</template>
  </u-simple-dropdown>
</template>

<script>
import { USimpleDropdown } from 'universkin-shared';

export default {
  name: 'QuestionnaireLanguageDropdown',
  components: { USimpleDropdown },
  props: {
    value: {
      type: String,
      required: true
    },
    options: {
      type: Array,
      required: true
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    dropdownOptions() {
      return this.options.map(({ code, title }) => ({ value: code, label: title }));
    },
    currentValue() {
      const { code, title } = this.options.find(
        ({ code: languageCode }) => this.value === languageCode
      );

      return { value: code, label: title };
    }
  },
  methods: {
    onInput({ value }) {
      this.$emit('input', { code: value });
    }
  }
};
</script>

<style lang="scss" scoped>
.questionnaire-language-select-dropdown {
  font-family: var(--u-font-family-Gilroy);
  font-size: 14px;
  font-weight: 400;
}
</style>
