<template>
  <div
    class="questionnaire-page flex flex-column flex--align-center"
    :class="pageClasses"
    :style="pageStyles"
  >
    <questionnaire-header
      class="questionnaire-page__header"
      :user-info="userInfo"
      :doctor-info="doctorInfo"
      :language="language"
      :language-options="languageOptions"
      :progress="progress"
      :time-left="timeLeft"
      :language-select-disabled="languageSelectDisabled"
      :is-time-left-message-visible="isTimeLeftMessageVisible"
      @change-language="$emit('change-language', $event)"
    />

    <div class="questionnaire-page-content-wrap" :class="contentWrapClasses">
      <transition name="fade-step" mode="out-in">
        <div
          :key="progress"
          class="questionnaire-page__content questionnaire-page-content-wrap__content flex flex-column flex--align-center"
        >
          <div
            v-if="hasHeading"
            class="page-heading questionnaire-page__heading flex flex-column flex--align-center"
          >
            <section-title
              v-if="shouldDisplaySectionTitle"
              class="page-heading__dysfunction-title"
              :section-name="sectionName"
            />

            <h2 class="page-heading__title questionnaire-page__title" :class="pageTitleClasses">
              {{ title }}
            </h2>

            <span
              v-if="subtitle"
              class="questionnaire-page__subtitle u-typography-helvetica u-text u-text--s"
            >
              {{ $t(subtitle) }}
            </span>
          </div>

          <div v-if="skinScoring" class="questionnaire-page__skin-scoring q-fit q-w360">
            <skin-scoring
              :dysfunction="dysfunction"
              :message="skinScoring.message"
              :score="skinScoring.score"
              :title="title"
            />
          </div>

          <template v-if="hasWhyWeAskSection">
            <div
              class="questionnaire-page__why-we-ask q-fit q-w360 u-typography-gilroy u-text u-text--s u-text--link"
              @click="openWhyWeAskThisModal"
            >
              {{ $t('questionnaire.whyWeAskThis') }}
            </div>

            <why-we-ask-this-modal />
          </template>

          <div v-if="!noDivider" class="questionnaire-page__divider q-fit q-w360"></div>

          <slot></slot>
        </div>
      </transition>
    </div>

    <footer v-if="!noFooter" class="footer questionnaire-page__footer" :class="footerClasses">
      <span v-if="errorMessage" class="footer__error u-typography-helvetica u-text u-text--xs">{{
        errorMessage
      }}</span>
      <div class="footer__action-buttons">
        <u-button
          class="footer__back-button footer__button"
          kind="subtle"
          size="small"
          :disabled="!previousStepVisible"
          @click="$emit('previous')"
          >{{ $t('action.back') }}</u-button
        >
        <u-button
          class="footer__next-button footer__button"
          size="small"
          :loading="isNextLoading"
          :disabled="!nextStepVisible"
          @click="$emit('next')"
          >{{ $t(nextButtonText) }}</u-button
        >
      </div>
    </footer>
  </div>
</template>

<script>
import { UButton } from 'universkin-shared';

import QuestionnaireHeader from '@/modules/questionnaire/new-design-components/QuestionnaireHeader';
import SectionTitle from '@/modules/questionnaire/new-design-components/SectionTitle';
import SkinScoring from '@/modules/questionnaire/new-design-components/skin-scoring/SkinScoring';
import WhyWeAskThisModal from '@/modules/questionnaire/new-design-components/why-we-ask-this/WhyWeAskThisModal';

export default {
  name: 'QuestionnairePageTemplate',
  components: {
    WhyWeAskThisModal,
    QuestionnaireHeader,
    UButton,
    SectionTitle,
    SkinScoring
  },
  props: {
    title: {
      type: String,
      required: true
    },
    language: {
      type: String,
      required: true
    },
    languageOptions: {
      type: Array,
      required: true
    },
    isNextLoading: {
      type: Boolean,
      default: false
    },
    progress: {
      type: Number,
      default: null
    },
    timeLeft: {
      type: Number,
      default: null
    },
    doctorInfo: {
      type: Object,
      required: true
    },
    userInfo: {
      type: Object,
      required: true
    },
    sectionName: {
      type: String,
      default: ''
    },
    dysfunction: {
      type: String,
      default: ''
    },
    languageSelectDisabled: {
      type: Boolean,
      default: false
    },
    isFinalStep: {
      type: Boolean,
      default: false
    },
    previousStepVisible: {
      type: Boolean,
      default: true
    },
    nextStepVisible: {
      type: Boolean,
      default: true
    },
    errorMessage: {
      type: String,
      default: ''
    },
    noFooter: {
      type: Boolean,
      default: false
    },
    noTimeTracker: {
      type: Boolean,
      default: false
    },
    dark: {
      type: Boolean,
      default: false
    },
    skinScoring: {
      type: Object,
      default: null
    },
    contentCentered: {
      type: Boolean,
      default: false
    },
    /* Base width in px for page content */
    contentBaseWidth: {
      type: Number,
      default: 558
    },
    basicBackground: {
      type: Boolean,
      default: false
    },
    subtitle: {
      type: String,
      default: ''
    },
    hideSectionTitle: {
      type: Boolean,
      default: false
    },
    hasWhyWeAskSection: {
      type: Boolean,
      default: false
    },
    noDivider: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    nextButtonText() {
      return this.isFinalStep ? 'action.submit' : 'action.next';
    },
    footerClasses() {
      return {
        'footer--error': this.errorMessage
      };
    },
    pageClasses() {
      return {
        'questionnaire-page--error': this.errorMessage,
        'questionnaire-background': !this.dark,
        'questionnaire-page--dark': this.dark,
        'questionnaire-page--basic-background': this.basicBackground,
        'questionnaire-page--with-time-left-message': this.isTimeLeftMessageVisible
      };
    },
    pageStyles() {
      let backgroundColor = '--u-color-white';

      if (!this.basicBackground) {
        backgroundColor = this.dysfunction
          ? `--u-color-${this.dysfunction}-50`
          : '--u-color-grey-50';
      }

      return {
        '--background-color': `var(${backgroundColor})`,
        '--content-base-width': `${this.contentBaseWidth}px`
      };
    },
    contentWrapClasses() {
      return {
        'questionnaire-page-content-wrap--centered': this.contentCentered,
        'questionnaire-page-content-wrap--no-footer': this.noFooter
      };
    },
    isTimeLeftMessageVisible() {
      return this.timeLeft !== null && this.progress !== null;
    },
    hasHeading() {
      return this.title || this.shouldDisplaySectionTitle;
    },
    shouldDisplaySectionTitle() {
      return this.sectionName && !this.hideSectionTitle;
    },
    pageTitleClasses() {
      if (this.$windowWidth.value >= 960) {
        return ['u-typography-helvetica', 'u-text', 'u-text--l'];
      }

      return ['u-typography-helvetica', 'u-text-display', 'u-text-display--s'];
    }
  },
  beforeMount() {
    if (this.noFooter) {
      return;
    }

    document.body.classList.add('overflow-y--hidden');
  },
  beforeDestroy() {
    if (this.noFooter) {
      return;
    }

    document.body.classList.remove('overflow-y--hidden');
  },
  methods: {
    openWhyWeAskThisModal() {
      const modalParams = {
        dysfunction: this.dysfunction
      };

      this.$modal.show('why-we-ask-this-modal', modalParams);
    }
  }
};
</script>

<style lang="scss" scoped>
@import '~universkin-shared/src/assets/styles/scss/sizes.scss';
@import '~@/modules/questionnaire/assets/scss/questionnaire-background.scss';

.questionnaire-page-content-wrap {
  padding: var(--page-padding) var(--page-padding) calc(var(--page-padding) + var(--footer-height));
  width: 100%;

  &__content {
    margin: 0 auto;
  }

  &--no-footer {
    padding: var(--page-padding);
  }
}

.questionnaire-page {
  --footer-height: 80px;
  --page-padding: 8px;

  overflow: hidden scroll;
  height: var(--screen-height);
  background-color: var(--background-color);

  &__header {
    flex: 0 0 auto;
    width: 100%;
  }

  &__title {
    color: var(--u-color-graphite);
  }

  &__subtitle {
    margin: 8px 0 24px 0;
    color: var(--u-color-grey-600);
  }

  &__divider {
    border-bottom: 1px solid var(--u-color-grey-200);
    margin: 32px 0;
  }

  &__heading {
    width: 100%;
    text-align: center;
  }

  &__skin-scoring {
    margin-top: 32px;
  }

  &__why-we-ask {
    text-align: center;
    text-decoration: underline;
    text-underline-offset: 2px;
    margin-top: 16px;
    color: var(--u-color-graphite);
    cursor: pointer;
  }

  &__content {
    width: var(--content-base-width);
    max-width: 100%;
    background-color: var(--u-color-white);
    border-radius: 10px;
    padding: 8px;
  }

  &--basic-background {
    .questionnaire-page__content {
      background-color: transparent;
    }
  }

  &__footer {
    height: var(--footer-height);
    width: 100%;
    border-top: 1px solid var(--u-color-grey-200);
  }

  &--dark {
    background-color: var(--u-color-grey-900);

    .questionnaire-page__title {
      color: var(--u-color-white);
    }
  }

  &--error {
    --footer-height: 90px;
  }
}

.page-heading {
  position: relative;

  &__dysfunction-title {
    margin-bottom: 16px;
  }

  &__title {
    margin: 0;
  }
}

.footer {
  position: fixed;
  left: 0;
  bottom: 0;
  z-index: 5;
  width: 100%;

  display: flex;
  align-items: center;
  justify-content: center;

  box-sizing: border-box;
  background-color: var(--u-color-white);

  &__error {
    width: 100%;
    position: absolute;
    top: 5px;
    left: 50%;
    transform: translateX(-50%);
    text-align: center;
    color: var(--u-color-error);
  }

  &__action-buttons {
    width: 100%;
    padding: 0 var(--page-padding);

    display: flex;
    justify-content: space-between;
  }

  &--error {
    .footer__button {
      margin-top: 8px;
    }
  }
}

.fade-step-enter-active,
.fade-step-leave-active {
  transition: opacity 0.1s;
}

.fade-step-enter,
.fade-step-leave-to {
  opacity: 0;
}

@media (min-width: 360px) {
  .questionnaire-page {
    --page-padding: 16px;

    &__content {
      padding: 16px;
    }
  }
}

@media (min-width: $desktop-start) {
  .questionnaire-page-content-wrap {
    &--centered {
      margin: auto;
    }
  }

  .questionnaire-page {
    &__heading {
      margin-top: 16px;
    }

    &--error {
      --footer-height: 80px;
    }

    &__skin-scoring {
      margin-top: 24px;
    }

    &__subtitle {
      display: block;
    }

    &__divider {
      margin: 40px 0;
    }

    &--with-time-left-message {
      .questionnaire-page__header {
        margin-bottom: 28px;
      }
    }
  }

  .page-heading {
    &__title {
      margin: 0;
    }
  }

  .footer {
    &--error {
      .footer__button {
        margin-top: 0;
      }
    }
  }
}
</style>
