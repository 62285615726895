const LOCALES = [
  ['ar', 'العربية', 'ae'],
  ['bg', 'БЪЛГАРСКИ', 'bg'],
  ['zh', '简体中文', 'cn'],
  ['zh-CN', '繁體中文', 'cn'],
  ['da', 'DANSK', 'da'],
  ['de', 'DEUTSCH', 'de'],
  ['nl', 'NEDERLANDS', 'nl'],
  ['en', 'ENGLISH', 'gb'],
  ['fr', 'FRANÇAIS', 'fr'],
  ['ka', 'ქართული', 'ka'],
  ['el', 'ΕΛΛΗΝΙΚΑ', 'gr'],
  ['it', 'ITALIANO', 'it'],
  ['lv', 'LATVIEŠU', 'lv'],
  ['lt', 'LIETUVIŲ', 'lt'],
  ['pl', 'POLSKI', 'pl'],
  ['pt', 'PORTUGUÊS', 'pt'],
  ['ro', 'ROMÂNĂ', 'ro'],
  ['ru', 'РУССКИЙ', 'ru'],
  ['es', 'ESPAÑOL', 'es'],
  ['es-MX', 'ESPAÑOL MEXICANO', 'mx'],
  ['sv', 'SVENSKA', 'sv'],
  ['tr', 'TÜRK', 'tr'],
  ['uk', 'УКРАЇНСЬКА', 'ua'],
  ['vi', 'TIẾNG VIỆT', 'vn']
];

const LANGUAGES = [
  ['en', 'ENGLISH', 'gb'],
  ['fr', 'FRANÇAIS', 'fr'],
  ['ru', 'РУССКИЙ', 'ru'],
  ['es-MX', 'ESPAÑOL MEXICANO', 'mx'],
  ['es', 'ESPAÑOL', 'es']
];

export { LOCALES, LANGUAGES };
