import { mapGetters } from 'vuex';
import { types } from '@/modules/questionnaire/store/types';

export const doctorAndPatientInfoMixin = {
  computed: {
    ...mapGetters({
      doctorAndPatientInfoMixinDoctor: types.getters.GET_DOCTOR,
      doctorAndPatientInfoMixinDoctorFullName: types.getters.GET_DOCTOR_FULL_NAME,
      doctorAndPatientInfoMixinPatient: types.getters.GET_PATIENT
    }),
    doctorInfo() {
      return {
        name: this.doctorAndPatientInfoMixinDoctorFullName,
        country: this.doctorAndPatientInfoMixinDoctor.country
      };
    },
    patientInfo() {
      return {
        name: this.doctorAndPatientInfoMixinPatient.firstName,
        email: this.doctorAndPatientInfoMixinPatient.email
      };
    }
  }
};
