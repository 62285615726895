<template>
  <div
    class="questionnaire-header u-typography-helvetica u-text u-text--xs"
    :class="headerClasses"
    :style="headerStyles"
  >
    <template v-if="userInfo">
      <span class="questionnaire-header__user-info questionnaire-header__user-info--mobile">
        <v-popover :offset="0" placement="right" popover-class="u-tooltip">
          <img
            class="questionnaire-header__user-pictogram"
            src="@/modules/questionnaire/assets/images/user-pictogram.svg"
            alt="user-info-icon"
          />

          <template slot="popover">
            <span class="flex flex-column">
              <span class="q-mb8 u-typography-helvetica u-text u-text--s">Patient</span>

              <span class="u-typography-helvetica u-text u-text--s u-text--link">{{
                userInfo.name
              }}</span>
              <span class="questionnaire-header__sub-text u-typography-helvetica u-text u-text--xs">
                {{ userInfo.email }}
              </span>
            </span>
          </template>
        </v-popover>
      </span>

      <div class="questionnaire-header__user-info questionnaire-header__user-info--desktop">
        <img
          class="questionnaire-header__user-pictogram q-mr16"
          src="@/modules/questionnaire/assets/images/user-pictogram.svg"
          alt="user-info-icon"
        />
        <span class="flex flex-column">
          <span class="u-typography-helvetica u-text u-text--m u-text--link">{{
            userInfo.name
          }}</span>
          <span class="questionnaire-header__sub-text u-typography-helvetica u-text u-text--xs">
            {{ userInfo.email }}
          </span>
        </span>
      </div>
    </template>

    <div
      v-if="doctorInfo"
      class="questionnaire-header__doctor-info doctor-info flex flex-column flex--align-center"
    >
      <span
        class="doctor-info__doctor-name doctor-info__doctor-name--desktop u-typography-helvetica u-text u-text--m u-text--link"
        >{{ doctorInfo.name }}</span
      >

      <span
        class="doctor-info__doctor-name doctor-info__doctor-name--mobile u-typography-helvetica u-text u-text--s u-text--link"
        >{{ doctorInfo.name }}</span
      >

      <span
        class="questionnaire-header__sub-text doctor-info__doctor-country u-typography-helvetica u-text u-text--xs"
        >{{ doctorCountryFullName }}</span
      >
    </div>

    <questionnaire-language-dropdown
      class="questionnaire-header__language-select"
      :value="language"
      :options="languageOptions"
      :disabled="languageSelectDisabled"
      @input="onLanguageChange"
    />

    <div
      v-if="isTimeLeftMessageVisible"
      class="questionnaire-header__time-left-message u-typography-helvetica u-text u-text--xs"
    >
      {{ timeLeftMessage }}
    </div>
  </div>
</template>

<script>
import QuestionnaireLanguageDropdown from '@/modules/questionnaire/new-design-components/QuestionnaireLanguageDropdown';

import { displayNameMixin } from '@/mixins/displayNameMixin';

export default {
  name: 'QuestionnaireHeader',
  components: { QuestionnaireLanguageDropdown },
  mixins: [displayNameMixin],
  props: {
    doctorInfo: {
      type: Object,
      default: null
    },
    userInfo: {
      type: Object,
      default: null
    },
    language: {
      type: String,
      required: true
    },
    languageOptions: {
      type: Array,
      required: true
    },
    progress: {
      type: Number,
      default: null
    },
    timeLeft: {
      type: Number,
      default: null
    },
    languageSelectDisabled: {
      type: Boolean,
      default: false
    },
    isTimeLeftMessageVisible: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    headerClasses() {
      return {
        'questionnaire-header--no-border': this.noBorder
      };
    },
    headerStyles() {
      return {
        '--progress-bar-width': `${this.progress}%`
      };
    },
    timeLeftMessage() {
      return this.timeLeft
        ? this.$t('label.progressBarTimeLeft', [this.timeLeft])
        : this.$t('label.progressBarWellDone');
    },
    doctorCountryFullName() {
      const iso = this.doctorInfo.country.toLowerCase();

      return this.getCountryDisplayName(iso);
    }
  },
  methods: {
    onLanguageChange({ code }) {
      this.$emit('change-language', code);
    }
  }
};
</script>

<style lang="scss" scoped>
@import '~universkin-shared/src/assets/styles/scss/sizes.scss';

.questionnaire-header {
  width: 100%;
  box-sizing: border-box;
  border-bottom: 1px solid var(--u-color-grey-200);
  position: relative;
  background-color: var(--u-color-white);
  height: 57px;

  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    height: 2px;
    transform: translateY(50%);
    background-color: var(--u-color-grey-900);
    width: var(--progress-bar-width);
  }

  &__user-info {
    position: absolute;
    left: 16px;
    top: 7px;

    &--desktop {
      display: none;
    }
  }

  &__doctor-info {
    position: absolute;
    left: 50%;
    top: 5px;
    transform: translateX(-50%);
  }

  &__language-select {
    position: absolute;
    right: 16px;
    top: 19px;
  }

  &__progress-bar {
    width: var(--width);
    border-bottom: 2px solid var(--u-color-grey-900);
    position: absolute;
    bottom: -1px;
    left: 0;
  }

  &__time-left-message {
    display: none;
    position: absolute;
    bottom: -28px;
    left: 50%;
    transform: translateX(-50%);
    color: var(--u-color-grey-600);
  }

  &__sub-text {
    color: var(--u-color-grey-500);
  }
}

.doctor-info {
  &__doctor-name {
    &--mobile {
      max-width: calc(100vw - 136px);
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }

    &--desktop {
      display: none;
    }
  }
}

@media (min-width: $desktop-start) {
  .questionnaire-header {
    height: 82px;

    &__user-info {
      top: 19px;

      &--desktop {
        display: flex;
      }

      &--mobile {
        display: none;
      }
    }

    &__doctor-info {
      top: 19px;
    }

    &__language-select {
      top: 32px;
    }

    &__time-left-message {
      display: block;
    }
  }

  .doctor-info {
    &__doctor-name {
      &--desktop {
        display: initial;
      }

      &--mobile {
        display: none;
      }
    }
  }
}
</style>
