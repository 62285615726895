<template>
  <div
    class="skin-scoring u-typography-helvetica u-text u-text--link u-text--xs"
    :style="skinScoringStyles"
  >
    <template v-if="hasScore">
      <div class="skin-scoring__score skin-scoring-bar">
        <div
          class="skin-scoring-bar__title u-typography-helvetica u-text u-text--link u-text--s q-mb8"
        >
          {{ scoreTitle }}
        </div>
        <progress-bar class="q-mb8" :color="dysfunction" :value="scorePercentage" tiny outlined />

        <div
          class="skin-scoring-bar__scale scoring-bar-scale q-mb16 u-typography-helvetica u-text u-text--link u-text--xs"
        >
          <div v-for="(n, index) in 11" :key="n" :class="getScoreClasses(index)">{{ index }}</div>
        </div>
      </div>
    </template>

    <span
      v-if="hasScore"
      class="skin-scoring__what-mean u-typography-gilroy u-text u-text--s u-text--link"
      @click="openModal"
      >{{ $t('questionnaire.whatDoesItMean') }}</span
    >
    <div v-else class="skin-scoring__message u-typography-helvetica u-text u-text--s">
      {{ $t(message) }}
    </div>

    <what-does-it-mean-modal
      ref="whatItMeansModal"
      :dysfunction="dysfunction"
      :message="message"
      :title="title"
      :description="subHeadingDescription"
    />
  </div>
</template>

<script>
import { camelCase, kebabCase } from 'lodash';

import ProgressBar from '@/modules/questionnaire/components/common/ProgressBar';
import WhatDoesItMeanModal from '@/modules/questionnaire/new-design-components/skin-scoring/WhatDoesItMeanModal';

import {
  DYSFUNCTION_NAMES,
  DYSFUNCTION_SEVERETY
} from '@/modules/questionnaire/constants/dysfunctions';

const SCORE_TITLE = {
  [DYSFUNCTION_NAMES.OXIDATIVE_STRESS]: {
    [DYSFUNCTION_SEVERETY.NO]: 'oxidativeStressState1',
    [DYSFUNCTION_SEVERETY.LOW]: 'oxidativeStressState2',
    [DYSFUNCTION_SEVERETY.MEDIUM]: 'oxidativeStressState3',
    [DYSFUNCTION_SEVERETY.HIGH]: 'oxidativeStressState4',
    [DYSFUNCTION_SEVERETY.VERY_HIGH]: 'oxidativeStressState5'
  },
  [DYSFUNCTION_NAMES.SKIN_APPEARANCE]: {
    [DYSFUNCTION_SEVERETY.NO]: 'skinAppearanceState1',
    [DYSFUNCTION_SEVERETY.LOW]: 'skinAppearanceState2',
    [DYSFUNCTION_SEVERETY.MEDIUM]: 'skinAppearanceState3',
    [DYSFUNCTION_SEVERETY.HIGH]: 'skinAppearanceState4',
    [DYSFUNCTION_SEVERETY.VERY_HIGH]: 'skinAppearanceState5'
  },
  [DYSFUNCTION_NAMES.SKIN_REDNESS]: {
    [DYSFUNCTION_SEVERETY.NO]: 'skinRednessState1',
    [DYSFUNCTION_SEVERETY.LOW]: 'skinRednessState2',
    [DYSFUNCTION_SEVERETY.MEDIUM]: 'skinRednessState3',
    [DYSFUNCTION_SEVERETY.HIGH]: 'skinRednessState4',
    [DYSFUNCTION_SEVERETY.VERY_HIGH]: 'skinRednessState5'
  },
  [DYSFUNCTION_NAMES.SKIN_DRYNESS]: {
    [DYSFUNCTION_SEVERETY.NO]: 'skinDrynessState1',
    [DYSFUNCTION_SEVERETY.LOW]: 'skinDrynessState2',
    [DYSFUNCTION_SEVERETY.MEDIUM]: 'skinDrynessState3',
    [DYSFUNCTION_SEVERETY.HIGH]: 'skinDrynessState4',
    [DYSFUNCTION_SEVERETY.VERY_HIGH]: 'skinDrynessState5'
  },
  [DYSFUNCTION_NAMES.SKIN_OILINESS]: {
    [DYSFUNCTION_SEVERETY.NO]: 'skinOilinessState1',
    [DYSFUNCTION_SEVERETY.LOW]: 'skinOilinessState2',
    [DYSFUNCTION_SEVERETY.MEDIUM]: 'skinOilinessState3',
    [DYSFUNCTION_SEVERETY.HIGH]: 'skinOilinessState4',
    [DYSFUNCTION_SEVERETY.VERY_HIGH]: 'skinOilinessState5'
  },
  [DYSFUNCTION_NAMES.SKIN_TEXTURE]: {
    [DYSFUNCTION_SEVERETY.NO]: 'skinTextureState1',
    [DYSFUNCTION_SEVERETY.LOW]: 'skinTextureState2',
    [DYSFUNCTION_SEVERETY.MEDIUM]: 'skinTextureState3',
    [DYSFUNCTION_SEVERETY.HIGH]: 'skinTextureState4',
    [DYSFUNCTION_SEVERETY.VERY_HIGH]: 'skinTextureState5'
  },
  [DYSFUNCTION_NAMES.PIMPLES]: {
    [DYSFUNCTION_SEVERETY.NO]: 'pimplesState1',
    [DYSFUNCTION_SEVERETY.LOW]: 'pimplesState2',
    [DYSFUNCTION_SEVERETY.MEDIUM]: 'pimplesState3',
    [DYSFUNCTION_SEVERETY.HIGH]: 'pimplesState4',
    [DYSFUNCTION_SEVERETY.VERY_HIGH]: 'pimplesState5'
  },
  [DYSFUNCTION_NAMES.SKIN_PIGMENTATION]: {
    [DYSFUNCTION_SEVERETY.NO]: 'skinPigmentationState1',
    [DYSFUNCTION_SEVERETY.LOW]: 'skinPigmentationState2',
    [DYSFUNCTION_SEVERETY.MEDIUM]: 'skinPigmentationState3',
    [DYSFUNCTION_SEVERETY.HIGH]: 'skinPigmentationState4',
    [DYSFUNCTION_SEVERETY.VERY_HIGH]: 'skinPigmentationState5'
  }
};

export default {
  name: 'SkinScoring',
  components: { ProgressBar, WhatDoesItMeanModal },
  props: {
    title: {
      type: String,
      required: true
    },
    dysfunction: {
      type: String,
      required: true
    },
    score: {
      type: Number,
      default: null
    },
    message: {
      type: String,
      required: true
    }
  },
  computed: {
    hasScore() {
      return this.score !== null;
    },
    scorePercentage() {
      return this.score * 10;
    },
    dysfunctionIcon() {
      return require(`universkin-shared/src/assets/images/svg/dysfunction-icons/${this.dysfunction}.svg`);
    },
    skinScoringStyles() {
      const color = kebabCase(this.dysfunction);

      return {
        color: `var(--u-color-${color}-500)`,
        '--message-background-color': `var(--u-color-${color}-50)`
      };
    },
    scoreTitle() {
      const scoreTitles = SCORE_TITLE[camelCase(this.dysfunction)];

      if (this.score === 0) {
        return this.$t(scoreTitles[DYSFUNCTION_SEVERETY.NO]);
      }

      if (this.score < 4) {
        return this.$t(scoreTitles[DYSFUNCTION_SEVERETY.LOW]);
      }

      if (this.score < 7) {
        return this.$t(scoreTitles[DYSFUNCTION_SEVERETY.MEDIUM]);
      }

      if (this.score < 9) {
        return this.$t(scoreTitles[DYSFUNCTION_SEVERETY.HIGH]);
      }

      return this.$t(scoreTitles[DYSFUNCTION_SEVERETY.VERY_HIGH]);
    },
    subHeadingDescription() {
      return `subHeading.${camelCase(this.dysfunction)}`;
    }
  },
  methods: {
    getScoreClasses(score) {
      return {
        'scoring-bar-scale__score--active': this.score === score
      };
    },
    openModal() {
      return this.$refs.whatItMeansModal.open();
    }
  }
};
</script>

<style lang="scss" scoped>
.skin-scoring {
  &__message {
    text-align: center;
    color: var(--u-color-grey-900);
  }

  &__what-mean {
    text-decoration: underline;
    text-underline-offset: 2px;
    color: var(--u-color-graphite);
    cursor: pointer;
  }
}

.skin-scoring-bar {
  &__scale {
    color: var(--u-color-grey-300);
  }
}

.scoring-bar-scale {
  display: flex;
  justify-content: space-between;

  &__score {
    &--active {
      color: var(--u-color-grey-900);
    }
  }
}

.skin-scoring-popover {
  position: relative;
  pointer-events: auto;

  &__close-button {
    position: absolute;
    top: 4px;
    right: 0;
    cursor: pointer;
  }
}
</style>
